import DOMPurify from 'isomorphic-dompurify';
import parseHTML from '../../../utilities/parseHTML';
import { filterAndSortRelatedPosts } from '../../../utilities/gridCardFilters';
import processHeadArgs from '../../common/process/processHeadArgs';
import { titleRelated, btnLabel } from '../Constants';
import { build } from '../../../Constants.json';

export const getImage = (featuredImage, title) =>
    featuredImage
        ? {
              alt: DOMPurify.sanitize(featuredImage?.node?.altText || title),
              data: featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
          }
        : null;

const processCategoryArgs = (queried) => 
{
    const categoryArgs = {
        titleRelated,
        btnLabel,
        listLinks: [],
    };

    const { category, childrens, posts, guides } = queried;
    const listLink = childrens?.edges;
    const listPosts = [...posts?.edges, ...guides?.edges];

    categoryArgs.postsRelated = filterAndSortRelatedPosts(0, listPosts, []);
    
    let featuredCard = {};

    const firstPost = categoryArgs.postsRelated.shift();

    const { title, link: url, featuredImage, author, readingtime } =
        firstPost || {};

    featuredCard = {
        title: DOMPurify.sanitize(title),
        url: DOMPurify.sanitize(url),
        author: DOMPurify.sanitize(author),
        minutes: DOMPurify.sanitize(readingtime),
        image: getImage(featuredImage, title),
    };

    categoryArgs.infoSection = {
        title: DOMPurify.sanitize(category.title || ''),
        description: '',
        featuredCard,
        categoryLinks: [],
    };

    const breadcrumbs = [[...category.cdmfields.breadcrumb].shift(), [...category.cdmfields.breadcrumb].pop()];

    categoryArgs.breadcrumbs = breadcrumbs.map((crumb) => ({
        id: crumb.id,
        term_id: crumb.term_id,
        text: DOMPurify.sanitize(crumb.text),
        url: DOMPurify.sanitize(crumb.url),
    }));

    const categories = listLink.filter(
        (item) => item.node?.name !== 'Uncategorized'
    );

    categoryArgs.listLinks = categories.map((link) => ({
        title: DOMPurify.sanitize(link?.node?.name),
        subtitle: parseHTML(link?.node?.description || ''),
        url: link?.node?.uri,
    }));

    return categoryArgs;
};
const processQueried = (queried, pageContext) => 
{
    const { metadata } = queried.category.cdmfields;

    metadata.canonical =`/${build.defaults.BLOG_SLUG}/`;

    const allArgs = {
        categoryArgs: processCategoryArgs(queried),
        headArgs: processHeadArgs(
            metadata, 
            queried.site.siteMetadata,
            pageContext
        ),
    };
    return allArgs;
};
export default processQueried;
