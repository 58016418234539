/**
 * This is the blog homepage "Learning Center", not the site homepage
 */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { bodyClass, bodyClassPrefix } from './Constants';
import Layout from '../../components/composite/Layout';
import Seo from '../../components/simple/seo';
import CategoryPage from '../../components/pages/CategoryPage';
import processQueried from './helpers/processQueried';
import parseLayoutProps from '../common/parse/parseLayoutProps';
import useLocation from '../../utilities/useLocation'

/* following imports are GraphQL fragments, they are used within the page query */
import {
    menuNodes,
    menuItemNodes,
    siteMetadata,
    homePageData,
    guideCardData
} from '../../utilities/fragments';

const HomePageContainer = ({ data, location, pageContext }) => 
{
    useLocation(location);
    const { footerProps, headerProps } = parseLayoutProps(data, location);
    const { categoryArgs, headArgs } = processQueried(data, pageContext);
    
    return (
        <Layout headerProps={headerProps} footerProps={footerProps}>
            <Seo
                helmetProps={headArgs}
                bodyClass={`${bodyClass} ${bodyClassPrefix}${data.post?.databaseId}`}
            />
            <CategoryPage categoryInfo={categoryArgs} currentPage={pageContext?.currentPage} />
        </Layout>
    );
};
export default HomePageContainer;
HomePageContainer.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    pageContext: PropTypes.objectOf(PropTypes.any).isRequired
};

export const pageQuery = graphql`
    query BlogHomePageById {
        site {
            ...siteMetadata
        }
        category: wpPost(slug: { eq: "learn" }) {
            ...homePageData
        }
        childrens: allWpCategory(filter: { count: { gt: 0 } }) {
            edges {
                node {
                    id
                    databaseId
                    name
                    uri
                    description
                }
            }
        }
        posts: allWpPost {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
        guides: allWpGuide {
            edges {
                node {
                    ...guideCardFields
                }
            }
        }
        headerMenuNav: allWpMenuItem(
            filter: { locations: { eq: MENU_1 }, parentDatabaseId: { eq: 0 } }
        ) {
            ...menuItemNodes
        }
        footerMenuPolicy: wpMenu(locations: { eq: MENU_2 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol1: wpMenu(locations: { eq: FOOTER_COL_1 }) {
            menuItems {
                ...menuNodes
            }
        }
        footerMenuCol2: wpMenu(locations: { eq: FOOTER_COL_2 }) {
            menuItems {
                ...menuNodes
            }
        }
    }
`;
